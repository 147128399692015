import React from 'react'
import MessengerMessageUs from 'react-messenger-message-us';

function FacebookMessageUs() {
  return (
      <>
          <MessengerMessageUs
              pageId={process.env.REACT_APP_FB_PAGE_ID}
              appId={process.env.REACT_APP_FB_APP_ID} 
              size='large'
              debug={false}
              autoLogAppEvents= {true}
        />
    </>
  )
}

export default FacebookMessageUs