import React from 'react'
import PrimaryContainer from './PrimaryContainer'
import { FaFacebook } from "react-icons/fa";
import { ImMail4 } from "react-icons/im";
import { FaLocationDot } from "react-icons/fa6";
import logoVariant from '../assets/images/text-logo-variant.png'
import { Link } from 'react-router-dom';
import ResponsiveIconLink from './ResponsiveIconLink';

const Footer = () => {
  return (
      <>
          <div className='w-full bg-[#4a4444] text-amber-50/50 py-10 px-6'>
              <PrimaryContainer>
                  <div className='grid grid-cols-[40%_auto_auto] gap-x-2 text-amber-50 text-sm md:grid-cols-1 lg:gap-x-12'>
                      <div className='space-y-4'>
                          <img src={logoVariant} alt='Lodi cakes and pastries' className='w-[300px] lg:w-[260px] h-auto fill-white' />

                          <div className='max-w-[300px] text-xs'>We aim to provide great customer service.
                              For inquiries and price quotation,
                              here are all the ways you can contact us
                          </div>

                          <div className='text-3xl flex gap-2'>
                              <ResponsiveIconLink icon={ <FaFacebook />} to="https://www.facebook.com/p/Lodi-Cakes-and-Pastries-100090899119391/" />
                              <ResponsiveIconLink icon={ <ImMail4 />} to="mailto:lodicakes.pastries@gmail.com" />
                              <ResponsiveIconLink icon={<FaLocationDot />} to="https://maps.app.goo.gl/PsWy9ftuZZLDqn7CA" />
                          </div>
                      </div>

                      <div className='space-y-3 flex flex-col gap-1 md:mt-10'>
                          <p className='font-baskervville text-xl font-bold'>SITE MAP</p>
                          <Link to="/">Home</Link>
                          <Link to="/products">Products</Link>
                          <Link to="/about-us">About</Link>
                          <Link to="/faq">FAQ</Link>
                          <Link to="/order-now/0">Order</Link>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-conditions">Term & Conditions</Link>
                      </div>

                      <div className='space-y-3 justify-self-end md:mt-10 md:justify-self-start'>
                          <p className='font-baskervville text-xl font-bold'>CONTACT INFO</p>
                          <p>If you have any question,<br />
                              please contact us at <strong className='text-amber-500/70'>lodicakes.pastries@gmail.com</strong>
                          </p>

                          <div className='max-w-[300px]'>Centro Mt. Nebo, Valencia City, Bukidnon, Philippines, 8709</div>

                          <div className='text-lg'>
                            <p>(+63) 935-447-3955</p>
                          </div>
                      </div>
                </div>


                  <div className='mt-12 border-t border-amber-100/20 py-2 text-left xs:text-xs md:text-sm'>
                    Copyright &copy; Lodi Cakes and Pastries 2024. All right reserved
                  </div>
              </PrimaryContainer>
          </div>
    </>
  )
}

export default Footer