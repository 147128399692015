import React from "react";
import { Outlet } from "react-router-dom";
import PrimaryContainer from "../components/PrimaryContainer";
import AdminNavigation from "../components/AdminNavigation";

const AdminLayout = () => {
    return (
        <div className="min-h-[100vh] pt-2 pb-28 text-neutral-600 bg-neutral-300/50">
            <PrimaryContainer>
                <AdminNavigation />
                <Outlet />
            </PrimaryContainer>
        </div>
    );
};

export default AdminLayout;
