import React, { useState, useEffect } from "react";
import FormInput from "../components/Forms/FormInput";
import FormSelect from "../components/Forms/FormSelect";
import PrimaryButton from "../components/PrimaryButton";
import PrimaryButtonOutline from "../components/PrimaryButtonOutline";
import AdminPrimaryContainer from "../components/AdminPrimaryContainer";
import { handleOnChange } from "../hooks/useHandleOnChange";
import FormInputFile from "../components/Forms/FormInputFile";
import { handleEditSubmit } from "../helper/product";
import { useParams } from "react-router-dom";
import { useProductDataById } from "../hooks/useProductDataById";
import noImage from "../assets/images/no-image.jpg";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import TinyTextEditor from "../components/Forms/TinyTextEditor";
import { SERVER_FILE_URL } from "../utils/utils";

const ProductEditPage = () => {
    const { id } = useParams();
    const { product } = useProductDataById(id);
    const [sliderImages, setSliderImages] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    const [formValues, setFormValues] = useState({
        title: "",
        description: "",
        price: "",
        isDiscount: false,
        discount: "",
        image: "",
    });
    const [featuredImage, setFeaturedImage] = useState([]);
    const [sliderImage, setSliderImage] = useState([]);

    useEffect(() => {
        if (product && Object.keys(product).length > 0) {
            setFormValues(() => ({
                title: product.title || "",
                description: product.description || "",
                price: product.price || "",
                isDiscount: product.isDiscount || false,
                discount: product.discount || "",
            }));

            setSliderImages(() =>
                product && product.sliderImages
                    ? product.sliderImages.split(",")
                    : []
            );
        }
    }, [product]);

    return (
        <>
            <AdminPrimaryContainer customClass="mt-3">
                <div className="px-8 py-6">
                    <div className="text-sm space-y-1">
                        <p className="text-xl font-medium">
                            Edit - {product && product.title}
                        </p>
                        <p>
                            - Only Alpha-Numeric characters are allowed(A-z) and
                            (0-9)
                        </p>
                        <p>
                            - Required fields are marked{" "}
                            <span className="text-red-500">*</span>
                        </p>
                    </div>

                    <form
                        className="mt-6"
                        onSubmit={(ev) =>
                            handleEditSubmit(
                                ev,
                                formValues,
                                featuredImage,
                                sliderImage,
                                product,
                                axiosPrivate
                            )
                        }
                    >
                        <div>
                            <div className="space-y-6">
                                <FormInput
                                    name="title"
                                    type="text"
                                    label="Product Name"
                                    value={formValues.title}
                                    onChange={(ev) =>
                                        handleOnChange(ev, setFormValues)
                                    }
                                    placeholder="Product name*"
                                    errorMessage="Name shouldn't include any special character!"
                                    pattern="[\w,. ]{3,100}$"
                                    customClass="!text-base"
                                    autoFocus
                                    required
                                />

                                <div className="w-full">
                                    <p className="capitalize py-1 inline-block font-medium text-neutral-500">
                                        Product description
                                    </p>
                                    <TinyTextEditor setFormValues={setFormValues} initialValue={formValues.description} />
                                </div>

                                <FormInput
                                    name="price"
                                    type="number"
                                    label="Product Price"
                                    value={formValues.price}
                                    onChange={(ev) =>
                                        handleOnChange(ev, setFormValues)
                                    }
                                    placeholder="Enter product price"
                                    errorMessage="Input must be a number greater than 0"
                                    pattern="^(?!0.00)(\d+\.)(\d\d)$"
                                    min={0}
                                    step="0.01"
                                    required={true}
                                    customClass="!text-base"
                                />

                                <FormSelect
                                    name="isDiscount"
                                    label="Is discount"
                                    value={formValues.isDiscount}
                                    onChange={(ev) =>
                                        handleOnChange(ev, setFormValues)
                                    }
                                    customClass="!text-base"
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </FormSelect>

                                <FormInput
                                    name="discount"
                                    type="number"
                                    label="Discount percentage"
                                    value={formValues.discount}
                                    onChange={(ev) =>
                                        handleOnChange(ev, setFormValues)
                                    }
                                    placeholder="Enter product discount percentage"
                                    errorMessage="Input must be a number greater than 0"
                                    pattern="^(?!0.00)(\d+\.)(\d\d)$"
                                    min={0}
                                    step="0.01"
                                    customClass="!text-base"
                                />

                                <div>
                                    <p className="capitalize py-1 inline-block font-medium text-neutral-500">
                                        Feature Image
                                    </p>
                                    <div className="flex justify-start gap-3">
                                        <img
                                            src={
                                                product && product.featuredImage
                                                    ? `${SERVER_FILE_URL}/uploads/${product.featuredImage}`
                                                    : noImage
                                            }
                                            alt={product && product.title}
                                            className="h-32 w-40 rounded"
                                        />

                                        <FormInputFile
                                            name="image"
                                            images={featuredImage}
                                            onChange={(ev) =>
                                                setFeaturedImage(
                                                    () => ev.target.files
                                                )
                                            }
                                        />
                                    </div>
                                </div>


                                <div>
                                <p className="capitalize py-1 inline-block font-medium text-neutral-500">
                                    Slider Images
                                </p>
                                <div className="space-y-3">
                                    <div className="flex gap-2 flex-wrap">
                                        {sliderImages.length > 0 ? (
                                            sliderImages.map((image, key) => (
                                                <img
                                                    key={key}
                                                    src={`${SERVER_FILE_URL}/uploads/${image}`}
                                                    alt={
                                                        product && product.title
                                                    }
                                                    className="h-32 w-auto rounded border border-gray-400"
                                                />
                                            ))
                                        ) : (
                                            <img
                                                src={noImage}
                                                alt={product && product.title}
                                                className="h-32 w-auto rounded border border-gray-200"
                                            />
                                        )}
                                    </div>
                                    <FormInputFile
                                        name="sliderImage"
                                        multiple
                                        images={sliderImage}
                                        onChange={(ev) =>
                                            setSliderImage(
                                                () => ev.target.files
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            </div>

                            
                        </div>

                        <div className="mt-5 flex justify-start gap-3">
                            <PrimaryButton type="submit">Save</PrimaryButton>
                            <PrimaryButtonOutline
                                onClick={() =>
                                    (window.location.href = "/products/manage")
                                }
                            >
                                Cancel
                            </PrimaryButtonOutline>
                        </div>
                    </form>
                </div>
            </AdminPrimaryContainer>
        </>
    );
};

export default ProductEditPage;
