import React from "react";

const FormSelect = ({ label, children, customClass, innerRef, required, ...rest }) => {
    return (
        <div className="w-full font-medium">
            {label && (
                <label className="capitalize inline-block font-medium py-1 text-neutral-500">
                    {label} {required && (
                        <span className="text-red-500">*</span>
                    )}
                </label>
            )}
            <select
                className={`w-full text-lg font-medium rounded-md border-[1px] outline-1 
                            placeholder:text-sm placeholder:font-normal border-neutral-300/40 py-2.5 px-3
                            shadow-lg shadow-orange-800/10
                            focus:outline-orange-500/80 ${customClass}`}
                ref={innerRef}
                {...rest}
            >
                {children}
                </select>
        </div>
    );
};

export default FormSelect;