export const O_ACTIONS = {
    SET_ORDER: 'set-order',
    DELETE_ORDER: 'delete-order',
    UPDATE_ORDER: 'update-order',
}

export const orderReducer = (orders, actions) => {
    switch (actions.type) {
        case O_ACTIONS.SET_ORDER:
            return actions.payload;
        case O_ACTIONS.DELETE_ORDER:
            return orders.filter((order) => order._id !== actions.payload);
        case O_ACTIONS.UPDATE_ORDER:
            return orders.map((order) =>
                order._id === actions.payload._id? {...actions.payload, status: 1} : order
            );
        default:
            return orders;
    }
}