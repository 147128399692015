import { useEffect, useState } from "react"
import axios from "../Axios/axios"
import { toast } from "react-toastify";

export const useProductsData = () => {
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const controller = new AbortController();

        const getProducts = async () => {
            try {
                const response = await axios.get("/products", { signal: controller.signal });
                isMounted && setProductData(response.data);
            } catch (error) {
                toast.error(error.message, { position: "bottom-right", autoClose: 3000 });
            } finally {
                setLoading(false);
            }
        }

        getProducts();

        //clean up functions
        return () => {
            isMounted = false;
            setLoading(false);
            isMounted && controller.abort();
        }
    }, []);

    return {
        productData,
        loading
    };
}