import { useEffect, useState } from "react";
import axios from "../Axios/axios"
import { toast } from "react-toastify";

export const useProductDataById = (id) => {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        axios
            .get(`/products/${id}`)
            .then((response) => {
                setProduct(response.data);
            })
            .catch((error) => {
                toast.error(error.message, { position: "bottom-right", autoClose: 3000 });
            });
    }, [id]);

    return {
        product
    }
}