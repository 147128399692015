import React from "react";
import PrimaryButton from "../components/PrimaryButton";
import { HiMiniHome } from "react-icons/hi2";
import dropCakeImg from "../assets/images/drop-cakes.png";
import LazyImage from "../components/LazyImage"

const NotFoundPage = () => {
    return (
        <div className="min-h-[100vh] bg-orange-400/10 text-neutral-700 flex items-center justify-center">
            <div className="-mt-20 w-fit flex justify-center items-center gap-12 px-6">
                <div className="text-center">
                    <h1 className="text-[130px] sm:text-6xl md:text-8xl lg:text-[110px] font-bold text-orange-700">
                        404
                    </h1>
                    <h2 className="text-2xl sm:text-lg md:text-xl font-bold">
                        Oops, page not found!
                    </h2>
                    <p className="mt-2 text-gray-500 md:text-sm">
                        The page you are looking for could not be found.
                    </p>
                    <div className="mt-8 w-fit mx-auto">
                        <PrimaryButton
                            onClick={() => (window.location.href = "/")}
                        >
                            <HiMiniHome />
                            Back to Homepage
                        </PrimaryButton>
                    </div>
                </div>

                <div className="sm:hidden">
                    <LazyImage
                        src={dropCakeImg}
                        alt="Page not found"
                        className="block rounded-lg h-[360px] w-[560px] md:h-[200px] md:w-[320px] lg:h-[250px] lg:w-[400px]"
                    />
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
