import React from 'react'

const AdminPrimaryContainer = ({children, customClass}) => {
  return (
      <div className={`bg-white p-2 rounded-lg border border-orange-900/30 shadow shadow-orange-900/20 ${customClass}`}>
        {children}
    </div>
  )
}

export default AdminPrimaryContainer