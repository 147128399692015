import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaRegCircleUser } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
import { RiUser3Fill } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import Dropdown from "./Dropdown";
import useLogout from "../hooks/useLogout";
import { handleLogout } from "../helper/user";
import useAuth from "../hooks/useAuth";

const AdminNavigation = () => {
    const logout = useLogout();
    const { auth } = useAuth();
    const navigate =useNavigate();

    const linkClass = ({ isActive }) => {
        return isActive
            ? "py-2 px-4 rounded bg-orange-500/50"
            : "py-2 px-4 rounded hover:text-orange-200 hover:bg-orange-500/50";
    };

    return (
        <div className="mt-0 px-5 text-sm rounded-md border border-orange-700/80 bg-orange-950/80 text-orange-50">
            <div className="flex justify-between items-center">
                <nav className="flex gap-5">
                    <NavLink to="/products/manage" className={linkClass}>
                        Manage Products
                    </NavLink>

                    <NavLink to="/orders/manage" className={linkClass}>
                        Order Request
                    </NavLink>
                </nav>

                <div className="flex items-center gap-2">
                    <FaRegCircleUser className="text-xl" />
                    <div>{auth.user}</div>

                    <div>
                        <Dropdown
                            list={[
                                {
                                    text: "Logout",
                                    onClick: () => handleLogout(logout, navigate),
                                    icon: <TbLogout />,
                                },
                                {
                                    text: "Profile",
                                    icon: <RiUser3Fill />,
                                },
                            ]}
                            icon={
                                <IoIosArrowDown className="text-lg text-orange-50" />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNavigation;
