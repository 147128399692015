/**
 * 
 * @returns returns url use in uploads
 */
export const SERVER_FILE_URL = process.env.REACT_APP_SERVER_FILE_URL ? process.env.REACT_APP_SERVER_FILE_URL : '';

/**
 *
 * @param {float numbers} num
 * @returns with with floating point numbers with comma separated values
 */
export const numberWithCommas = (num) => {
    if (num === null || num === undefined) {
        return 0;
    }

    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
};

/**
 *
 * @param {number} num
 * @param {number} decimalPlaces
 * @returns returns rounded number
 * **/
export const bankerRound = (num, decimalPlaces = 2) => {
    const d = decimalPlaces || 0;
    const m = Math.pow(10, d);
    const n = +(d ? num * m : num).toFixed(8);
    const i = Math.floor(n),
        f = n - i;
    const e = 1e-8;
    const r =
        f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
};



/** Convert input date into short date - ex: '03/26/24, 7:45 AM'  */
export const toShortDateTime = (inputDate) => {
    const date = new Date(inputDate);
    const dateOption = { year: "numeric", month: "short", day: "numeric" };
    const timeOption = { timeStyle: "short" };
    return `${date.toLocaleString(["en-Us"], dateOption)} ${date.toLocaleString(
        [],
        timeOption
    )}`;
};

export const wait = (timeOut) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, timeOut);
    });
}

/**
 * 
 * @param {String with HTML tags} text 
 * @returns - return text without html tags
 */
export const stripHtmlTags = (text) => {
    const regex = /(<([^>]+)>)/gi;
    const result = text ? text.replace(regex, "") : "";
    return result;
}

/**
 * 
 * @param {String}
 * @param {Number} - maximum number of characters
 * @returns - return string with the maximum number of characters
 */
export const limitStrLength = (text, maxLength) => {
    if (text.length > maxLength - 3) {
        return text.substring(0, maxLength).trimEnd() + "..."
    }
    else {
        return text
    }
}