import React from "react";
import { AsyncImage } from 'loadable-image'
import { Blur } from 'transitions-kit'
import LazyImageSpinner from "./LazyImageSpinner";

const LazyImage = (props) => {
    return (
        <div>
            <AsyncImage
                src={props.src}
                alt={props.alt}
                className={props.className}
                Transition={props => <Blur radius={10} {...props}/>}
                loader={<div className="w-full h-full flex items-center justify-end bg-neutral-200 rounded-lg border border-gray-200 opacity-50">
                    <LazyImageSpinner loading={true} />
                </div>}
            />
        </div>
    );
};

export default LazyImage;
