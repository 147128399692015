import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

const DrawerMenu = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen((prev)=> !prev);
    }

    useEffect(() => {
        if (isOpen) {
            document.getElementById("sideBar").style.width = "100%";
            document.getElementById("sideNav").style.width = "65%";
        } else {
            document.getElementById("sideBar").style.width = "0";
            document.getElementById("sideNav").style.width = "0";
        }
    }, [isOpen])

  return (
      <>
          <nav className="flex justify-between items-center">
                {/* menu icon svg to trigger the slide-in menu when clicked */}
                <div onClick={toggleNav}>
                    <FaBars className='text-2xl' />
                </div>

                {/* background overlay element, will close navbar when clicked */}
                <div onClick={()=> setIsOpen(false)}  id="sideBar" className="fixed top-0 left-0 bg-orange-950/30 w-0 h-full overflow-x-hidden duration-500 z-10">

                    {/* navigation menu box */}
                    <div id="sideNav" className="fixed top-0 left-0 bg-black/90 text-neutral-50 w-0 h-full flex justify-start items-start overflow-x-hidden duration-500  z-50">

                      {/* exit icon, will close navbar when clicked */}
                      <button
                          type='button'
                          className='absolute top-3 right-3'
                          onClick={()=> setIsOpen(false)}
                      >
                        <FaXmark className='text-white text-lg'/>
                      </button>
                        
                        <div>
                            <div className="whitespace-nowrap font-semibold sm:p-3 md:text-lg md:p-4 border-b border-neutral-600">MAIN MENU</div>

                            {/* menu links */}
                            <div className="flex flex-col font-medium md:text-lg sm:mt-4 sm:pl-6 sm:space-y-4 md:mt-8 md:pl-10 md:space-y-5">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
    </>
  )
}

export default DrawerMenu