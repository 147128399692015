import React, { useState, useRef, useEffect } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";

const Dropdown = ({ list, icon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonMenu = useRef(null);

    useEffect(
        () =>
            window.addEventListener("click", (ev) => {
                if (buttonMenu.current && buttonMenu.current.contains(ev.target)) {
                    setIsOpen(!isOpen);
                } else {
                    setIsOpen(false);
                }
            }),
        []
    );

    const classes =
        "w-full flex items-center gap-2 py-2 pl-2 pr-10 text-left whitespace-nowrap rounded border-[1px] border-transparent";
    const dangerClasses = "hover:text-red-600 hover:bg-red-50 hover:border-red-200"
    const primaryClasses = "hover:text-teal-900 hover:bg-teal-50 hover:border-teal-100"

    return (
        <div className="relative !z-[1000] text-sm text-neutral-800" data-dropdown_menu>
            <button
                ref={buttonMenu}
                className="p-2 rounded border-[1px] border-transparent outline-none hover:bg-orange-500/30 hover:border-orange-300"
            >
              {icon ? icon : ( <HiDotsVertical className="text-lg select-none" />)} 
            </button>

            {isOpen && (
                <div className="absolute !z-[2147483647] w-fit h-fit p-1 top-6 -right-1 flex flex-col items-start rounded-lg bg-white border-[1px] border-gray-200 drop-shadow-sm">
                    {list.map((item, i) => (
                        item.type === "link" ? (
                            <Link
                                key={i}
                                className={
                                    `${classes}  ${item.variant && item.variant ==='danger' ?  dangerClasses : primaryClasses}`
                                }
                                to={item.to}
                            >
                                {item.text}
                            </Link>
                        ) : (
                            <button
                                key={i}
                                    className={
                                    `${classes}  ${item.variant && item.variant ==='danger' ?  dangerClasses : primaryClasses}`
                                }
                                onClick={()=> item.onClick()}
                                >
                                {item.icon && item.icon}
                                {item.text}
                            </button>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
