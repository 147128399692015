import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/text-logo.png";
import PrimaryContainer from "./PrimaryContainer";
import DrawerMenu from "./DrawerMenu";

const Navigation = () => {
    const navClasses = ({ isActive }) => {
        return isActive ? "font-bold text-orange-500" : "";
    };

    return (
        <div className="bg-orange-200/20 border border-orange-950/10">
            <PrimaryContainer>
                <nav className="w-full h-20 px-3 sm:pr-6 md:pr-10 flex items-center justify-between font-poppins text-sm text-gray-600">

                    <img onClick={()=> window.location.href="/"} src={logo} alt="logo" className="h-10 w-[140px]" />
   

                    {/* big screen menu navigation */}
                    <div className="whitespace-nowrap pr-12 flex gap-x-10 md:hidden lg:gap-x-8 lg:pr-10">
                        <NavLink to="/" className={navClasses}>
                            Home
                        </NavLink>
                        <NavLink to="/products" className={navClasses}>
                            Cakes
                        </NavLink>
                        <NavLink to="/faq" className={navClasses}>
                            FAQ
                        </NavLink>
                        <NavLink to="/about-us" className={navClasses}>
                            Our Story
                        </NavLink>
                        <NavLink to="/order-now/0" className={navClasses}>
                            Order Now
                        </NavLink>
                    </div>

                    {/* mobile menu */}
                    <div className="hidden md:block">
                        <DrawerMenu>
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/products">Cakes</NavLink>
                            <NavLink to="/faq">FAQ</NavLink>
                            <NavLink to="/about-us">Our Story</NavLink>
                            <NavLink to="/order-now/0">Order Now</NavLink>
                        </DrawerMenu>
                    </div>
                </nav>
            </PrimaryContainer>
        </div>
    );
};

export default Navigation;
