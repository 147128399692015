import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

const override = {
    display: 'block',
    margin: 'auto',
}

const LazyImageSpinner = ({loading}) => {
  return (
      <ClipLoader 
          color="rgba(215, 117, 27, 1)"
          loading={loading}
          cssOverride={override}
          size={40}
    />
  )
}

export default LazyImageSpinner;
