import { toast } from "react-toastify";
import axios from "../Axios/axios";
import Swal from "sweetalert2";

export const handleLogin = async (ev, formValues, setAuth, navigate, from) => {
    ev.preventDefault();

    try {
        const response = await axios.post(
            "/auth/login",
            { email: formValues.email, password: formValues.password },
            {
                withCredentials: true,
            }
        );

        const accessToken = response?.data?.accessToken;
        const user = response?.data?.user;

        setAuth({user, email: formValues.email, accessToken });
        navigate(from, { replace: true }); // return user from 

    } catch (error) { 
        toast.error(error.message, { position: "top-right", autoClose: 3000 });
    }
}

const signOut = async (logout, navigate) => { 
    await logout();
    navigate('/');
}

export const handleLogout = (logout, navigate) => {
    Swal.fire({
        html: `<p style='font-size: 18px; font-weight: bold;'>Are you sure you want to logout?</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, I am sure!",
        cancelButtonText: "No, cancel it!",
    }).then((result) => {
        if (result.isConfirmed) {
            signOut(logout, navigate);
        }
    });
}