import React, {Suspense} from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import Spinner from "../components/Spinner";
import ScrollTop from '../components/ScrollTop';
import FacebookMessageUs from '../components/FacebookMessageUs';

const MainLayout = () => {
  return (
    <div className='min-h-[100vh] bg-yellow-100/5 text-neutral-700'>
      <Suspense fallback={<Spinner loading={true} />}>
        <Navigation />
        <Outlet />
        <Footer />
        <div className='fixed bottom-2 right-4 flex items-center justify-center gap-2'>
          <FacebookMessageUs />
          {/* <ScrollTop /> */}
        </div>
      </Suspense>
    </div>
  )
}

export default MainLayout