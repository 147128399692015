import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { p_ACTIONS } from "../reducer/productReducer";

export const handleCreateSubmit = async (
    ev,
    formValues,
    featuredImage,
    sliderImage,
    setSuccess,
    axiosPrivate
) => {
    ev.preventDefault();

    if (
        formValues.isDiscount === "true" &&
        (formValues.discount === "" || parseFloat(formValues.discount) === 0)
    ) {
        toast.error("Discount must be greater than zero!");
        document.querySelector("input[name='discount']")?.focus();
        return;
    }

    let sliderImgArr = [];
    sliderImgArr =
        Object.keys(sliderImage).length > 0 ? Array.from(sliderImage) : [];
    featuredImage[0] && sliderImgArr.push(featuredImage[0]);

    const formData = new FormData();

    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    formData.append("price", formValues.price);
    formData.append("isDiscount", formValues.isDiscount);
    formData.append("discount", formValues.discount);
    formData.append(
        "featuredImage",
        featuredImage[0] ? featuredImage[0].name : ""
    );
    formData.append(
        "sliderImages",
        sliderImgArr.length > 0
            ? sliderImgArr.map((image) => image.name).join(",")
            : ""
    );
    sliderImgArr.forEach((file) => {
        formData.append("images", file);
    });

    try {

        Swal.fire({
            title: "Creating new records...",
            html: "Please wait a moment",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
              }
          })
        
        const response = await axiosPrivate.post("/products/create", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 200 || response.statusText === "ok") {
            Swal.fire({
                title: "Success",
                text: "Product has been created successfully",
                icon: "success",
                confirmButtonColor: "green",
            }).then(() => {
                setSuccess(true);
                Swal.close();
            });
        }
    } catch (err) {
        toast.error(
            err && err.response.data.error ? err.response.data.error : err.message,
            { position: "top-right", autoClose: 3000 }
        );
        Swal.close();
    } 
};

const deleteProduct = async (product, productDispatcher, axiosPrivate) => {
    try {
        const response = await axiosPrivate.delete("/products", {data: product});
        if (response.status === 200 || response.statusText === "OK") {
            toast.success("Product deleted successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            productDispatcher({
                type: p_ACTIONS.DELETE_PRODUCT,
                payload: product._id,
            });
        }
    } catch (err) {
        toast.error(err.message, {
            position: "top-right",
            autoClose: 3000,
        });
    }
};

export const handleProductDelete = (
    product,
    productDispatcher,
    axiosPrivate
) => {
    Swal.fire({
        html: `<p style='font-size: 18px; font-weight: bold;'>Are you sure you want to delete this product
                <br/>"${product.title}"</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, I am sure!",
        cancelButtonText: "No, cancel it!",
    }).then((result) => {
        if (result.isConfirmed) {
            deleteProduct(product, productDispatcher, axiosPrivate);
        }
    });
};

export const handleEditSubmit = async (
    ev,
    formValues,
    featuredImage,
    sliderImage,
    product,
    axiosPrivate
) => {
    ev.preventDefault();

    let sliderImgArr = [];
    sliderImgArr =
        Object.keys(sliderImage).length > 0 ? Array.from(sliderImage) : [];
    featuredImage[0] && sliderImgArr.push(featuredImage[0]);

    const formData = new FormData();

    formData.append("id", product._id);
    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    formData.append("price", formValues.price);
    formData.append("isDiscount", formValues.isDiscount);
    formData.append("discount", formValues.discount);
    formData.append(
        "featuredImage",
        featuredImage[0]
            ? featuredImage[0].name
            : product.featuredImage
            ? product.featuredImage
            : ""
    );
    formData.append(
        "sliderImages",
        sliderImgArr.length > 0
            ? product.sliderImages
                ? product.sliderImages +
                  "," +
                  sliderImgArr.map((image) => image.name).join(",")
                : sliderImgArr.map((image) => image.name).join(",")
            : product.sliderImages
            ? product.sliderImages
            : ""
    );
    sliderImgArr.forEach((file) => {
        formData.append("images", file);
    });

    try {

        Swal.fire({
            title: "Creating new records...",
            html: "Please wait a moment",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
              }
          })

        const response = await axiosPrivate.patch(
            "/products/update",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        if (response.status === 200 || response.statusText === "ok") {
            Swal.fire({
                title: "Success",
                text: "Product has been created successfully",
                icon: "success",
                confirmButtonColor: "green",
            }).then(() => {
                window.location.href = "/products/manage";
                Swal.close();
            });
        }
    } catch (error) {
        toast.error(
           error && error.response.data.error
                ? error.response.data.error
                : error.message,
            { position: "top-right", autoClose: 3000 }
        );
        Swal.close();
    } 
};
