import React from "react";
import Pagination from "./Pagination";

const PaginationFooter = ({
    currentPage,
    totalCount,
    pageSize,
    setCurrentPage,
    setPageSize
}) => {
    var start = (currentPage - 1) * pageSize + 1;
    var end = totalCount;

    if (pageSize < totalCount) {
        end = pageSize * currentPage;
        if (end > totalCount) {
            end = totalCount;
        }
    }

    return (
        <div className="mt-2 px-2 flex justify-between items-center text-sm">
            <div>
                <span>
                    {start} - {end} of {totalCount}
                </span>
                <span className="pl-10">Rows per page:</span>
                <select className="outline-0 bg-transparent" value={pageSize} onChange={(ev)=> setPageSize(ev.target.value)}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="100">100</option>
                </select>
            </div>

            <div className="w-fit">
                <Pagination
                    className="w-full flex justify-between"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
};

export default PaginationFooter;
