import React, { lazy} from 'react';
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout"
import AdminLayout from "./layouts/AdminLayout";

import ProductManagePage from "./pages/ProductManagePage";
import ProductCreatePage from "./pages/ProductCreatePage";
import OrdersPage from './pages/OrdersPage';
import ProductEditPage from "./pages/ProductEditPage";
import { wait } from './utils/utils';
import RequireAuth from './components/RequireAuth';
import GuestLayout from './layouts/GuestLayout';
import LoginPage from './pages/LoginPage';
import PersistLogin from './components/PersistLogin';
import NotFoundPage from './pages/NotFoundPage';

function App() {

  const HomePage = lazy(() => wait(300).then(()=> import('./pages/HomePage')));
  const ProductsPage = lazy(() => wait(300).then(()=> import('./pages/ProductsPage')));
  const ProductDetailsPage = lazy(() =>  wait(300).then(()=> import('./pages/ProductDetailsPage')));
  const OrderCreatePage = lazy(() => wait(300).then(()=> import('./pages/OrderCreatePage')));
  const FaqPage = lazy(() => wait(300).then(() => import('./pages/FaqPage')));
  const PrivacyPolicyPage = lazy(() => wait(300).then(() => import('./pages/PrivacyPolicyPage')));
  const TermsConditions = lazy(() => wait(300).then(() => import('./pages/TermsConditions')));
  const AboutUsPage = lazy(() => wait(300).then(() => import('./pages/AboutUsPage')));

  return (
  
      <Routes>
        {/* public routes */}
        <Route path="/" element={<MainLayout />} >
          <Route index element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductDetailsPage />} />
          <Route path="/order-now/:id" element={<OrderCreatePage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/about-us" element={<AboutUsPage />} />
        </Route>

        {/* private routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<AdminLayout />} >
              <Route path="/products/manage" element={<ProductManagePage />} />
              <Route path="/products/create" element={<ProductCreatePage />} />
              <Route path="/orders/manage" element={<OrdersPage />} />
              <Route path="/products/edit/:id" element={<ProductEditPage />} />
            </Route>
          </Route>
        </Route>

          <Route element={<GuestLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
      
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
  );
}

export default App;
