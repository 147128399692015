import axios from "../Axios/axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { O_ACTIONS } from "../reducer/orderReducer";

export const handleSubmitForm = (ev, formData, setFormValues, captchaRef, navigate, from) => {
    ev.preventDefault();

    if (formData.cake === "" || formData.cake === undefined) {
        toast.error("Please select a cake", { position: "top-right", autoClose: 3000 });
        document.querySelector("select[name='cake']")?.focus();
        return;
    }
    const options = {
        url: '/orders/create',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: { ...formData, token: captchaRef.current.getValue()},
    }

    axios(options).then((response) => { 
        if (response.status === 200 || response.statusText === 'ok') { 
            Swal.fire({
                title: "Success",
                text: "Thanks for sending us your order request. Please hang tight while we get back to you!",
                icon: "success",
                confirmButtonColor: "green",
            }).then(() => {
                setFormValues({
                    cake: "",
                    name: "",
                    email: "",
                    phone: "",
                    address: "",
                    inscription: "",
                });
                captchaRef.current.reset();
                navigate(from, { replace: true });
            })
        }
    }).catch((error) => { 
        toast.error("Failed to send order request " + error.response.data.error, { position: "top-right", autoClose: 3000 });
    })
}

const deleteOrderRequest = async (orderRequest, orderDispatch, axiosPrivate) => {
    try {
        const response = await axiosPrivate.delete("/orders", { data: { id: orderRequest._id } });
        if (response.status === 200 || response.statusText === 'ok') { 
            toast.success("Order deleted successfully", { position: "top-right", autoClose: 3000 });
            orderDispatch({
                type: O_ACTIONS.DELETE_ORDER,
                payload: orderRequest._id,
            })
        }
    } catch (error) {
        toast.error("Failed to delete order request " + error.message, { position: "top-right", autoClose: 3000 });
    }
}

export const handleDeleteOrderRequest = (orderRequest, orderDispatch, axiosPrivate) => {
    Swal.fire({
        html: `<p style='font-size: 18px; font-weight: bold;'>Are you sure you want to delete this request
                <br/>"${orderRequest.name} - ${orderRequest.cake}"</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, I am sure!",
        cancelButtonText: "No, cancel it!",
    }).then((result) => {
        if (result.isConfirmed) {
            deleteOrderRequest(orderRequest, orderDispatch, axiosPrivate);
        }
    });
}

export const handleUpdateOrderRequest = async (orderRequest, orderDispatch, axiosPrivate) => { 
    try {
        const response = await axiosPrivate.patch("/orders/update", orderRequest);
        if (response.status === 200) {
            orderDispatch({
                type: O_ACTIONS.UPDATE_ORDER,
                payload: orderRequest,
            });
        }
    } catch (error) { 
        toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
        })
    }
}