import React from 'react'
import { Link } from 'react-router-dom'

const ResponsiveIconLink = ({icon, to}) => {
  return (
      <Link
      to={to}
      target='_blank'
          className='cursor-pointer duration-300 hover:transform hover:scale-125'
      >
      {icon}    
    </Link>
  )
}

export default ResponsiveIconLink