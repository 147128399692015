// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IMAGE GALLERY CUSTOM CSS */
.ReactGridGallery_tile {
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    border: 1px solid rgba(148, 59, 0, 0.20);
    border-radius: 10px;
    transition-duration: 0.3s;
    transition-property: transform;
}

.ReactGridGallery_tile:hover {
    transform: scale(1.05);
    box-shadow: 0 7px 14px rgba(148, 59, 0, 0.2), 0 10px 10px rgba(94, 69, 3, 0.22);
    border: 1px solid rgba(148, 59, 0, 0.60);
}

.ReactGridGallery > div {
    gap: 10px;
}

.ReactGridGallery_tile img {
    border-radius: 9px;
}


/* IMAGE SLIDER CUSTOMER CSS */
.slider-container .slick-list {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin: 0  -10px !important;
    box-sizing: border-box;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
    opacity: 30% !important;
}

/* FORM INPUT */
input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ .error{
    display: block;
}

/* bullet list */
ul,
ol {
    margin-left: 20px;
}

ul>li,
ol>li {
    list-style-type: disc;
    margin: 5px 0;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,sBAAsB;IACtB,yCAAyC;IACzC,oCAAoC;IACpC,wCAAwC;IACxC,mBAAmB;IACnB,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;IACtB,+EAA+E;IAC/E,wCAAwC;AAC5C;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;;AAGA,8BAA8B;AAC9B;IACI,4BAA4B;IAC5B,+BAA+B;IAC/B,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;;IAEI,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA,eAAe;AACf;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA,gBAAgB;AAChB;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,qBAAqB;IACrB,aAAa;AACjB","sourcesContent":["/* IMAGE GALLERY CUSTOM CSS */\n.ReactGridGallery_tile {\n    vertical-align: middle;\n    transform: perspective(1px) translateZ(0);\n    box-shadow: 0 0 1px rgba(0, 0, 0, 0);\n    border: 1px solid rgba(148, 59, 0, 0.20);\n    border-radius: 10px;\n    transition-duration: 0.3s;\n    transition-property: transform;\n}\n\n.ReactGridGallery_tile:hover {\n    transform: scale(1.05);\n    box-shadow: 0 7px 14px rgba(148, 59, 0, 0.2), 0 10px 10px rgba(94, 69, 3, 0.22);\n    border: 1px solid rgba(148, 59, 0, 0.60);\n}\n\n.ReactGridGallery > div {\n    gap: 10px;\n}\n\n.ReactGridGallery_tile img {\n    border-radius: 9px;\n}\n\n\n/* IMAGE SLIDER CUSTOMER CSS */\n.slider-container .slick-list {\n    padding-top: 20px !important;\n    padding-bottom: 20px !important;\n    margin: 0  -10px !important;\n    box-sizing: border-box;\n}\n\n.slick-prev:before,\n.slick-next:before {\n    color: black !important;\n    opacity: 30% !important;\n}\n\n/* FORM INPUT */\ninput:invalid[focused=\"true\"]{\n    border: 1px solid red;\n}\n\ninput:invalid[focused=\"true\"] ~ .error{\n    display: block;\n}\n\n/* bullet list */\nul,\nol {\n    margin-left: 20px;\n}\n\nul>li,\nol>li {\n    list-style-type: disc;\n    margin: 5px 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
