import React, { useState, useReducer, useEffect} from "react";
import { toShortDateTime } from "../utils/utils";
import { GrView } from "react-icons/gr";
import { FaTrashCan } from "react-icons/fa6";
import PaginationFooter from "../components/PaginationFooter";
import AdminPrimaryContainer from "../components/AdminPrimaryContainer";
import { useOrderRequestData } from "../hooks/useOrderRequestData";
import { handleDeleteOrderRequest, handleUpdateOrderRequest } from "../helper/order";
import { O_ACTIONS, orderReducer } from "../reducer/orderReducer";
import { usePaginationDataFilter } from "../hooks/usePaginationDataFilter";
import { Modal } from "../components/Modal";
import OrderRequestDetails from "../components/OrderRequestDetails";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const OrdersPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [orders, orderDispatch] = useReducer(orderReducer, [])
    const { orderData } = useOrderRequestData();
    const {
        currentData,
        setCurrentPage,
        currentPage,
        pageSize,
        setPageSize,
    } = usePaginationDataFilter(orders && orders);
    const [modal, setModal] = useState({ show: false });
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        if (orderData && orderData.length > 0) {
            orderDispatch({
                type: O_ACTIONS.SET_ORDER,
                payload: orderData
            })
        }
    }, [orderData]);

    return (
        <>
            <div className="pb-28">
                <div className="mt-6 flex justify-start">
                    <span className="font-semibold self-end">
                        Order Request Received
                    </span>
                </div>

                <AdminPrimaryContainer customClass="mt-2">
                    <div className="border-[1px] h-fit rounded border-gray-200">
                        <table className="w-full table-auto bg-white text-left">
                            <thead className="text-xs text-gray-500 bg-gray-200 uppercase">
                                <tr className="border-b-[1px] border-gray-200">
                                    <th className="py-3 pl-3">Name</th>
                                    <th className="py-3 px-2">Email</th>
                                    <th className="py-3 px-2">Phone</th>
                                    <th className="py-3 px-2">Address</th>
                                    <th className="py-3 px-2">Cake</th>
                                    <th className="py-3 px-2">Inscription</th>
                                    <th className="py-3 px-2">Status</th>
                                    <th className="py-3 px-2">DATE</th>
                                    <th className="py-3 px-2 text-center">
                                        ACTION
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="text-sm divide-y divide-gray-200 text-gray-700">
                                {currentData.length > 0 ? (
                                    currentData.map((order, key) => (
                                        <tr key={key}>
                                            <td className="py-3 pl-3">
                                                {order.name}
                                            </td>
                                            <td className="py-3 px-2">
                                                {order.email}
                                            </td>
                                            <td className="py-3 px-2">
                                                {order.phone}
                                            </td>
                                            <td className="py-3 px-2">
                                                {order.address}
                                            </td>
                                            <td className="py-3 px-2">
                                                {order.cake && order.cake.title}
                                            </td>
                                            <td className="py-3 px-2">
                                                {order.inscription}
                                            </td>
                                            <td className="py-3 px-2">
                                                <span
                                                    className={`py-[2px] px-2 text-xs font-medium border rounded ${
                                                        order.status === 0
                                                            ? "bg-orange-100  border-orange-300 text-orange-800"
                                                            : "bg-green-100 border-green-300 rounded text-green-800"
                                                    }`}
                                                >
                                                    {order.status === 0
                                                        ? "Unread"
                                                        : "Read"}
                                                </span>
                                            </td>
                                            <td className="py-3 px-2">
                                                {toShortDateTime(order.createdAt)}
                                            </td>
                                            <td className="py-3 px-2 text-center">
                                                <div className="flex gap-2 justify-center text-base">
                                                    <button onClick={() => { setModal({ show: true }); setOrderDetails(order); handleUpdateOrderRequest(order, orderDispatch, axiosPrivate) }}>
                                                        <GrView className="text-green-700" />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleDeleteOrderRequest(
                                                                order, orderDispatch, axiosPrivate
                                                            )
                                                        }
                                                    >
                                                        <FaTrashCan className="text-red-700/60" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center p-2">
                                            No order request received!
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </AdminPrimaryContainer>

                <div className="mt-1">
                    <PaginationFooter
                        currentPage={currentPage}
                        totalCount={orders.length}
                        pageSize={pageSize}
                        setCurrentPage={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </div>
            </div>

            <Modal show={modal.show} setModal={setModal} title="Order Request Details">
                <OrderRequestDetails setModal={setModal} orderDetails={orderDetails} />
            </Modal>
        </>
    );
};

export default OrdersPage;
