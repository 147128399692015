import { useEffect, useState } from "react"
import useAxiosPrivate from "./useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

export const useOrderRequestData = () => {
    const [orderData, setOrderData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getOrderRequest = async () => {
            try {
                const response = await axiosPrivate.get("/orders", {  signal: controller.signal });
                isMounted && setOrderData(response.data);
            } catch (error) {
                console.error(error);
                navigate("/login", {state: {from: location}, replace: true});
            }
        }

        getOrderRequest();

        //clean up functions
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, []);

    return {
        orderData
    };
}