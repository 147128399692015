import { useMemo, useState } from "react";

export const usePaginationDataFilter = (data=[]) => {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, data, pageSize]);

    return {
        currentData,
        setCurrentPage,
        currentPage,
        pageSize,
        setPageSize,
    }
}