import React from 'react'

const PrimaryContainer = ({customClass, children}) => {
  return (
    <div className={`max-w-6xl mx-auto ${customClass}`}>
          {children}
    </div>
  )
}

export default PrimaryContainer