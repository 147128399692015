import React from "react";
import { toShortDateTime } from "../utils/utils";
import PrimaryButtonOutline from "./PrimaryButtonOutline";

const OrderRequestDetails = ({ orderDetails, setModal }) => {

    return (
        <>
            <div className="mt-6">
                <table className="w-full table-auto text-left">
                    <tbody className="divide-y divide-gray-200">
                        <tr>
                            <td className="px-2 py-3">
                                Name
                            </td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">Email</td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.email}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">Phone</td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.phone && orderDetails.phone}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">Address</td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.address &&
                                    orderDetails.address}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">Selected Cake</td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.cake &&
                                    orderDetails.cake.title}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">Cake Inscription</td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.inscription &&
                                    orderDetails.inscription}
                            </td>
                        </tr>
                        <tr>
                            <td className="px-2 py-3">
                                Date Submitted
                            </td>
                            <td className="px-2 py-3 font-medium">
                                {orderDetails.createdAt &&
                                    toShortDateTime(
                                        orderDetails.createdAt
                                    )}
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>

            <div className="flex justify-end">
                <PrimaryButtonOutline onClick={()=> setModal({show: false})}>
                    Back
                </PrimaryButtonOutline>
            </div>
        </>
    );
};

export default OrderRequestDetails;
