import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";

function ScrollTop() {
    const [isShow, setIsShow] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleButtonVisibility = () => {
        const position = window.scrollY;
        setScrollPosition(() => position);

        setIsShow(() => (scrollPosition > 300 ? true : false));
    };

    useEffect(() => {
        window.addEventListener("scroll", handleButtonVisibility);
    });

    return (
        <>
            {isShow && (
                <button
                    onClick={() => handleScrollTop()}
                    className="flex items-center justify-center gap-2 rounded-md py-2 px-3 border border-orange-600/70 bg-orange-700/60"
                >
                    <MdOutlineKeyboardDoubleArrowUp className="text-white text-lg" />
                </button>
            )}
        </>
    );
}

export default ScrollTop;
