import React from "react";
import { FaImage } from "react-icons/fa";

const FormInputFile = ({ images, label, ...rest }) => {
    const files = images ? Array.from(images) : [];

    return (
        <div className="w-full">
            {label && (
                <label className="capitalize py-1 inline-block font-medium text-neutral-500">
                    {label}
                </label>
            )}
            <label
                className="w-full block min-h-32 rounded-md border-[2px] border-dashed hover:bg-neutral-50 
                hover:border-orange-300 hover:shadow-md hover:shadow-orange-200 hover:cursor-pointer"
            >
                <div className="flex flex-wrap gap-4 p-1 overflow-hidden">
                    {files &&
                        files.map((file, key) => (
                            <img
                                key={key}
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                className="h-28 w-auto rounded-md border border-gray-200"
                            />
                        ))}
                </div>

                {files.length === 0 && (
                    <div className="mt-1 h-full w-full flex items-center justify-center text-gray-400">
                        <div className="h-fit w-fit flex flex-col items-center">
                            <FaImage className="text-3xl" />
                            <p className="pt-1 font-medium">Select a photo</p>
                            <p className="mt-1 font-normal sm:text-xs text-center">
                                You are allowed only to upload
                                "PNG/JPEG/JPG/WEBP" image file
                                <br />
                                format and maximum each file limit is 1mb
                            </p>
                        </div>
                    </div>
                )}

                <input
                    type="file"
                    hidden
                    accept=".jpg, .jpeg, .png, .webp|image/*"
                    {...rest}
                />
            </label>
        </div>
    );
};

export default FormInputFile;
