import React from "react";
import { IoMdClose } from "react-icons/io";

export const Modal = ({ children, show, setModal, redirectUrl, title = null }) => {

    const handleCloseWindow = () => {
        if (redirectUrl) {
            window.location.href = "#/";
        } else {
            setModal({ show: false });
        }
    };

    if (!show) {
        return null;
    }
    
    return (
        <>
            {/* OVERLAY */}
            <div className="fixed top-0 left-0 bottom-0 w-full h-full z-[50] bg-black/20 backdrop-blur-sm"></div>

            {/* MODAL CONTENT */}
            <div className="fixed z-[55] top-0 left-0 bottom-0 h-full w-full flex justify-center items-center transition ease-in-out duration-150">
                <div className="relative w-fit min-w-[300px] min-h-[150px] bg-white py-6 px-10 rounded-lg border-[1px] border-gray-500 drop-shadow-md text-center text-sm text-gray-500">
                    <button
                        onClick={() => handleCloseWindow()}
                        className="absolute right-2 top-2 p-1 text-xl rounded border border-gray-200 hover:text-red-500"
                    >
                        <IoMdClose className="text-2xl" />
                    </button>
                    {title && (
                        <div className="uppercase font-semibold text-base text-left">{title}</div>
                    )}
                    
                    {children}
                </div>
            </div>
        </>
    );
    


    
};
