import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import  FormInput  from "../components/Forms/FormInput"
import  PrimaryButton  from "../components/PrimaryButton";
import PrimaryButtonOutline from "../components/PrimaryButtonOutline";
import { handleOnChange } from "../hooks/useHandleOnChange";
import { handleLogin } from "../helper/user";
import { FaLock, FaUser } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdLogin } from "react-icons/md";
import useAuth from "../hooks/useAuth";

const LoginPage = () => {
    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
    });
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { setAuth, persist, setPersist } = useAuth();

    useEffect(() => {
        localStorage.setItem("persist", persist);
    },[persist])

    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    return (
        <div className="w-[350px] bg-white rounded-lg border border-orange-800/30 text-center px-10 py-8 font-sm drop-shadow-sm">
            <p className="text-left font-semibold text-3xl text-orange-900/60">
                Log In
            </p>
            <form
                className="mt-4"
                onSubmit={(ev) =>
                  handleLogin(ev, formValues, setAuth, navigate, from)
                }
            >
                <div className="space-y-6">
                    <div className="relative text-left">
                        <FormInput
                            name="email"
                            type="email"
                            placeholder="Enter user email"
                            errorMessage="Invalid email address"
                            required={true}
                            customClass="text-base pl-8"
                            value={formValues.email}
                            onChange={(ev) => handleOnChange(ev, setFormValues)}
                            autoFocus
                        />

                        <FaUser className="absolute bottom-4 left-2 text-lg text-gray-400" />
                    </div>

                    <div className="relative">
                        <FormInput
                            name="password"
                            type="password"
                            placeholder="Enter your password"
                            required={true}
                            customClass="text-base pl-8"
                            pattern="^.{4,35}$"
                            errorMessage="Password should be 4-35 characters!"
                            value={formValues.password}
                            onChange={(ev) => handleOnChange(ev, setFormValues)}
                        />
                        <FaLock className="absolute bottom-4 left-2 text-lg text-gray-400" />
                    </div>
                </div>

                <div className="mt-3 text-left flex items-center gap-2">
                    <input
                        type="checkbox"
                        id="persist"
                        onChange={togglePersist}
                        checked={persist}
                    />
                    <label htmlFor="persist">Trust this device</label>
                </div>

                <div className="mt-6 flex gap-3 justify-end items-center">
                    <PrimaryButton type="submit">
                        <MdLogin />
                        <span>Login</span>
                    </PrimaryButton>
                    <PrimaryButtonOutline
                        onClick={() => (window.location.href = "/")}
                    >
                        <IoMdCloseCircleOutline /> <span>Home</span>
                    </PrimaryButtonOutline>
                </div>

                
            </form>
        </div>
    );
};

export default LoginPage;
