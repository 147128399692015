import React, { useEffect, useReducer } from "react";
import { useProductsData } from "../hooks/useProductsData";
import { SERVER_FILE_URL, limitStrLength, stripHtmlTags, toShortDateTime } from "../utils/utils";
import { GrView } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";
import PaginationFooter from "../components/PaginationFooter";
import PrimaryButton from "../components/PrimaryButton";
import noImage from "../assets/images/no-image.jpg";
import AdminPrimaryContainer from "../components/AdminPrimaryContainer";
import { usePaginationDataFilter } from "../hooks/usePaginationDataFilter";
import { p_ACTIONS, productReducer } from "../reducer/productReducer";
import { handleProductDelete } from "../helper/product";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ProductManagePage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [products, productDispatcher] = useReducer(productReducer, []);
    const {
        productData
    } = useProductsData();
    const {
        currentData,
        setCurrentPage,
        currentPage,
        pageSize,
        setPageSize,
    } = usePaginationDataFilter(products && products);

    useEffect(() => {
        if (productData && productData.length > 0) {
            productDispatcher({
                type: p_ACTIONS.SET_PRODUCT,
                payload: productData,
            })
        }
    }, [productData]);

    return (
        <>
            <div className="mt-6 flex justify-between">
                <span className="font-semibold self-end">
                    Product Management
                </span>
                <PrimaryButton
                    onClick={() => (window.location.href = "/products/create")}
                >
                    Create
                </PrimaryButton>
            </div>

            <AdminPrimaryContainer customClass="mt-2">
                <div className="border-[1px] h-fit rounded border-gray-200">
                    <table className="w-full bg-white text-left">
                        <thead className="text-xs text-gray-500 bg-gray-200 uppercase">
                            <tr className="border-b-[1px] border-gray-200">
                                <th className="py-3 pl-3">Title</th>
                                <th className="py-3 px-2">Description</th>
                                <th className="py-3 px-2 text-center">Price</th>
                                <th className="py-3 px-2 text-center">Discount</th>
                                <th className="py-3 px-2 text-center">Image</th>
                                <th className="py-3 px-2">DATE</th>
                                <th className="py-3 px-2 text-center">ACTION</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm divide-y divide-gray-200 text-gray-700">
                            {currentData.length > 0 ? (
                                currentData.map((product, key) => (
                                    <tr key={key}>
                                        <td className="py-3 pl-3">
                                            {product.title}
                                        </td>
                                        <td className="py-3 px-2">
                                            {limitStrLength(stripHtmlTags(product.description), 50)}
                                        </td>
                                        <td className="py-3 px-2 text-center">
                                            {product.price}
                                        </td>
                                        <td className="py-3 px-2 text-center">
                                            {product.isDiscount === true && product.discount ? product.discount + "%" : ''}
                                        </td>

                                        <td className="py-3 px-2 text-center">
                                            <img
                                                src={product.featuredImage ? `${SERVER_FILE_URL}/uploads/${product.featuredImage}` : noImage}
                                                alt={product.title} 
                                                className="h-12 w-auto mx-auto"
                                            />
                                        </td>

                                        <td className="py-3 px-2">
                                            {toShortDateTime(product.createdAt)}
                                        </td>
                                        <td className="py-3 px-2 text-center">
                                            <div className="flex gap-2 justify-center text-base">
                                                <button onClick={()=> window.location.href = `/products/${product._id}`}>
                                                    <GrView className="text-green-700" />
                                                </button>
                                                <button onClick={()=> window.location.href=`/products/edit/${product._id}`}>
                                                    <FaEdit className="text-green-700" />
                                                </button>
                                                <button onClick={()=> handleProductDelete(product, productDispatcher, axiosPrivate)}>
                                                    <FaTrashCan className="text-red-700/60" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center p-2">
                                        No products added!
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </AdminPrimaryContainer>

            <div className="mt-1">
                <PaginationFooter
                    currentPage={currentPage}
                    totalCount={products.length}
                    pageSize={pageSize}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                />
            </div>
        </>
    );
};

export default ProductManagePage;
