export const p_ACTIONS = {
    SET_PRODUCT: "set-product",
    DELETE_PRODUCT: "delete-product",
}

export const productReducer = (products, actions) => { 
    switch (actions.type) {
        case p_ACTIONS.SET_PRODUCT:
            return actions.payload;
        case p_ACTIONS.DELETE_PRODUCT:
            return products.filter((product) => product._id!==actions.payload);
        default:
            return products;
    }
}